import React from "react";
import Styles from "./WhatsappExamplesSection.module.scss";
import StartFreeButton from "../../cmps/StartFreeButton/StartFreeButton.cmp";
import { messageSendArrow } from "../../utils/assetsImports";
import { useTranslation } from "react-i18next";
import { languageModel } from "../../store/atoms/generalAtoms";
import { useRecoilValue } from "recoil";

interface WhatsappExamplesSectionProps {
  firstMessage?: string;
  secondMessage?: any;
  title: string;
  photo: string;
  buttonStyles?: any;
  buttonAndLabelStyles?: any;
  imgStyles?: any;
  onClickMethod?: any;
  scrollToTop?: boolean;
}

export default function WhatsappExamplesSection({
  firstMessage,
  secondMessage,
  title,
  photo,
  buttonStyles,
  buttonAndLabelStyles,
  imgStyles,
  onClickMethod,
  scrollToTop,
}: WhatsappExamplesSectionProps) {
  const [t, i18n] = useTranslation();
  const recoilLangModel = useRecoilValue(languageModel);

  return (
    <div id="GPT4" className={Styles.WhatsAppExampleSectionContainer}>
      <div className={Styles.CustomWhatsAppImgContainer} style={imgStyles}>
        <img src={photo} alt="" style={{ borderRadius: "10px" }} />
      </div>
      {/* } */}
      <div
        className={`${Styles.ThirdRowStartFreeDiv} ${
          recoilLangModel.toLocaleLowerCase().includes("he")
            ? Styles.HebClass
            : ""
        }`}
        style={buttonAndLabelStyles}
      >
        <p>{title}</p>
        <StartFreeButton
          //   title={t("WhatsAppSection.StartFreeButton")}
          title={t("WhatsAppPage.FourthSection.buttonTitle")}
          negative
          customStyle={buttonStyles}
          onClickMethod={onClickMethod}
          //   scrollToTop
          // backgroundColor="#181818"
        />
      </div>
    </div>
  );
}
