import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AboutMyself.module.scss";

interface SectionProps {
  openModalFunction: () => void;
}

const AboutMyself = ({ openModalFunction }: SectionProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`${styles.container} ${
        !i18n.language.includes("he") ? styles.containerLtr : ""
      }`}
    >
      <div className={styles.content}>
        <div className={styles.textContent}>
          <h2 className={styles.heading}>{t("LpPage.aboutMyself.heading")}</h2>
          <h2 className={styles.subheading}>
            {t("LpPage.aboutMyself.subheading1")}
          </h2>
          <h2 className={styles.boldSubheading}>
            {t("LpPage.aboutMyself.boldSubheading")}
          </h2>
          <h2 className={styles.subheading}>
            {t("LpPage.aboutMyself.subheading2")}
          </h2>
        </div>
        <div className={styles.imageContainer}>
          <img
            src="/assets/aboutmyself.svg"
            alt="Profile"
            className={styles.image}
          />
        </div>
      </div>
      <div className={styles.additionalContent}>
        <h2 className={styles.largeHeading}>
          {t("LpPage.aboutMyself.largeHeading1")}
        </h2>
        <p className={styles.paragraph}>{t("LpPage.aboutMyself.paragraph1")}</p>
        <p className={styles.paragraph}>{t("LpPage.aboutMyself.paragraph2")}</p>
        <p className={styles.paragraph}>{t("LpPage.aboutMyself.paragraph3")}</p>
        <h2 className={styles.largeHeading}>
          {t("LpPage.aboutMyself.largeHeading2")}
        </h2>
        <p className={styles.coloredParagraph}>
          {t("LpPage.aboutMyself.coloredParagraph")}
        </p>
        <h2 className={styles.coloredHeading}>
          {t("LpPage.aboutMyself.coloredHeading")}
        </h2>
      </div>
    </div>
  );
};

export default AboutMyself;
