import React, { useEffect, useLayoutEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Styles from './PricingPage.module.scss'
import PlanCardsSection from '../../PageSections/PlanCardsSection/PlanCardsSection.cmp'
import FaqSection from '../../PageSections/FaqSection/FaqSection.cmp'
import UniContact from '../../cmps/UniContact/UniContact.cmp'
import { checkToken, extractUserData, storage } from '../../utils/utils.service'
import { useRecoilState } from 'recoil'
import { clientPhoneNumber } from '../../store/atoms/contactUsAtom'
import { openBillingModal } from '../../store/atoms/pricingPlanAtom';

export default function PricingPage() {
    const navigate = useNavigate();
    const [userPhone, setUserPhone] = useRecoilState(clientPhoneNumber)
    const [billingModalState, setBillingModalState] = useRecoilState(openBillingModal)


    // useLayoutEffect(() => {
    //     checkToken(navigate)

    // }, [])

    useEffect(() => {
        // checkToken(navigate)
        // Gets the data from the url params
        const clientUrlParams = extractUserData.extractUrlParams()
        // Gets the data from the cookies
        const gaClientId = extractUserData.getGAClientId()
        const token = extractUserData.extractUrlToken()
        const { user_id } = extractUserData.extractUrlUserId()
        const { planId } = extractUserData.extractUrlPlanId()

        let urlUserPhone: string = extractUserData.extractUrlPhoneNumber().userPhone || '';
        if (urlUserPhone !== "") {
            // console.log(urlUserPhone)
            storage.saveToStorage("userPhone", `+${urlUserPhone}`);
            setUserPhone(`+${urlUserPhone}`)
        }

        if (token.token) {
            // console.log(token)
            storage.saveToStorage("userToken", token.token)
        }

        if (planId) {
            // console.log(user_id)
            storage.saveToSessionStorage("billingPlanParams", {
                planId: planId,
                yearly: false,
            });

        }
        if (user_id) {
            storage.saveToStorage("userId", user_id)
            // setBillingModalState(true)
        }
        // Saves all the data as an object to local storage.
        storage.saveToStorage("clientFullData", { ...clientUrlParams, gaClientId })
    }, [])
    return (
        <div id="/pricing" className={`${Styles.UniPricingPageMainContainer} animate__animated animate__fadeIn`}>
            <PlanCardsSection toggleButtonNegative={true} />
            <FaqSection />
            <UniContact />
        </div>
    )
}
