import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// Styles
import Styles from "./UniHomePage.module.scss";
import "animate.css";
// Components
import UniContact from "../../cmps/UniContact/UniContact.cmp";
import UniFooter from "../../cmps/UniFooter/UniFooter.cmp";
import StartFreeButton from "../../cmps/StartFreeButton/StartFreeButton.cmp";
import FiguresCards, {
  FigureCard,
} from "../../cmps/FiguresCard/FiguresCard.cmp";
import PricingPlanCards, {
  PricingPlanCard,
} from "../../cmps/PricingPlanCard/PricingPlanCards.cmp";
import AboutAccordion, {
  UniAccordion,
} from "../../cmps/AboutAccordion/AboutAccordion.cmp";
import AboutInfoCard, {
  AboutInfoCardInterface,
} from "../../cmps/AboutInfoCard/AboutInfoCard.cmp";
// Util helpers
import {
  customersIMG,
  designIMG,
  trophyIMG,
  savingMoney,
  hrConsulting,
  businessInvestment,
  businessAudit,
  langIcon,
  unicornImg,
  unicornSVG,
  whatsAppChatDemo,
  multiAI,
  gpt4Motion,
  claudeImage,
  whatsAppAndChatExample,
  webAppIcon,
  whatsAppIMG,
  whiteWhatsAppIMG,
} from "../../utils/assetsImports";
import {
  // accordionsData,
  infoCardsData,
  // getPricingCardsData,
  getFigureCardsData,
  getServiceDataCardsData,
} from "../../utils/HardCodedData";
// Sections
import StartFreeAndImg from "../../PageSections/StartFreeAndImg/StartFreeAndImg.cmp";
import PlanCardsSection from "../../PageSections/PlanCardsSection/PlanCardsSection.cmp";
import WhatsappExamplesSection from "../../PageSections/WhatsappExamplesSection/WhatsappExamplesSection.cmp";
import FaqSection from "../../PageSections/FaqSection/FaqSection.cmp";
// Utils
import {
  checkToken,
  extractUserData,
  storage,
} from "../../utils/utils.service";
// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { clientPhoneNumber } from "../../store/atoms/contactUsAtom";
// Imports
import { useTranslation } from "react-i18next";
import { languageModel } from "../../store/atoms/generalAtoms";
import { Helmet } from "react-helmet";
import {
  buttonAndLabelStyles,
  buttonAndLabelStylesTwoButtons,
  buttonAndLabelStylesWhatsAppSection,
} from "../../utils/constant";
import ServicesCompare from "../../cmps/ServicesCompare/ServicesCompare.cmp";
import ServiceDataCard, {
  ServiceDataCardsInterface,
} from "../../cmps/ServiceDataCard/ServiceDataCard.cmp";
import LetsChatModal from "../../cmps/LetsChatModal/LetsChatModal.cmp";
import VideoSection from "../../PageSections/VideoSection/VideoSection";

// const whatsAppExampleFirstMessage = `Act as my copywriter and write me an SEO-driven article on DSLR
// cameras `;

// const whatsAppExampleSecondMessage = (
//   <p>
//     Sure,
//     <br />
//     Title: "Unlocking the Power of DSLR Cameras: A Guide for Photography
//     Enthusiasts" In the realm of photography, the Digital Single-Lens Reflex
//     (DSLR) camera reigns supreme.
//     <br />
//     <br />
//     Unparalleled in its versatility and image quality, it has become the go-to
//     gadget for professional photographers and hobbyists alike. But what is it
//     about DSLR cameras that set them apart in a crowded market?...
//   </p>
// );

export default function UniHomePage() {
  const navigate = useNavigate();
  const [figuresData, setFiguresData] = useState<FigureCard[]>(
    getFigureCardsData()
  );
  const homePageRef = useRef(null);
  const recoilLangModel = useRecoilValue(languageModel);
  // const [pricingPlanCards, setPricingPlanCards] =
  //   useState<PricingPlanCard[]>(getPricingCardsData());
  // const [aboutAccordionsData, setAboutAccordionsData] =
  //   useState<UniAccordion[]>(accordionsData);
  // const [infoCards, setInfoCards] =
  //   useState<AboutInfoCardInterface[]>(infoCardsData);
  const [serviceDataCardsInfo, setServiceDataCardsInfo] = useState<
    ServiceDataCardsInterface[]
  >(getServiceDataCardsData());
  const [userPhone, setUserPhone] = useRecoilState(clientPhoneNumber);
  const { t, i18n } = useTranslation();
  const [isBelowThreshold, setIsBelowThreshold] = useState(
    window.innerWidth < 1378
  );
  const [openWhatsAppModal, setOpenWhatsAppModal] = useState<boolean>(false);

  const handleOpenWhatsAppModal = () => {
    setOpenWhatsAppModal(!openWhatsAppModal);
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set whether screen width is below threshold
      setIsBelowThreshold(window.innerWidth < 1378);
    }

    // Attach event listener
    window.addEventListener("resize", handleResize);

    // Detach event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  // Gets clients data from url params as soon as he lands in that page
  useEffect(() => {
    // checkToken(navigate)
    const { FAQ } = extractUserData.extractUrlFAQ();
    // console.log("🚀 ~ file: UniHomePage.cmp.tsx:89 ~ useEffect ~ FAQ:", FAQ)
    if (FAQ) {
      const mainSectionDiv: HTMLElement | null = document.getElementById(`faq`);
      // console.log(mainSectionDiv)
      if (mainSectionDiv) {
        mainSectionDiv!.scrollIntoView({
          behavior: "instant" as ScrollBehavior,
          block: "start",
        });
        // setTimeout(() => window.scrollBy(0, 0));
      }
    }
    // Gets the data from the url params
    const clientUrlParams = extractUserData.extractUrlParams();
    // Gets the data from the cookies
    const gaClientId = extractUserData.getGAClientId();

    // If the params contains userPhone, it will set a global state with it and save it to local storage.
    let urlUserPhone: string =
      extractUserData.extractUrlPhoneNumber().userPhone || "";
    if (urlUserPhone !== "") {
      // console.log(urlUserPhone)
      storage.saveToStorage("userPhone", `+${urlUserPhone}`);
      setUserPhone(`+${urlUserPhone}`);
    }
    // Saves all the data as an object to local storage.
    storage.saveToStorage("clientFullData", { ...clientUrlParams, gaClientId });
    // console.log(recoilLangModel)
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("homePageHelmet.title")}</title>
        <meta
          name="description"
          content={`${t("homePageHelmet.description")}`}
        ></meta>
      </Helmet>
      <div
        ref={homePageRef}
        id="/"
        className={`${Styles.UniHomePageMainContainer} animate__animated animate__fadeIn `}
      >
        {/* <div className={Styles.HomePageFirstRowItemContainer} style={{ direction: recoilLangModel.toLocaleLowerCase().includes('he') ? "rtl" : "ltr" }}>
          <img
            src={require("../../assets/homePageAssets/icon.png")}
            alt="AvatarPhoto"
          /> */}
        {/* <span>Hi 👋 I am Unichat, and I'll...</span> */}
        {/* <span>{t('homePagefirstSection.headerSpan')}</span> */}
        {/* <p>Bring Midjourney & GPT-4 to your WhatsApp</p> */}
        {/* <p>{t('homePagefirstSection.headerParagarph')}</p>
          <StartFreeButton title={t('homePagefirstSection.StartFreeButton')} />
        </div> */}
        <StartFreeAndImg
          // imgStyle={{ height: "100%", width: "100%", maxWidth: "700px" }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStylesTwoButtons["he"]
                    : buttonAndLabelStylesTwoButtons["en"]),
                  margin: "4rem 0",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStylesTwoButtons["he"]
              : buttonAndLabelStylesTwoButtons["en"]
          }
          // buttonAndLabelStyles={{ marginRight: "2rem" }}
          containerStyles={{ alignItems: "center" }}
          imagesContainerStyles={{ width: "87%" }}
          reverse
          buttonStyles={
            !isBelowThreshold
              ? { margin: "5rem 0 0", width: "300px" }
              : { margin: "0 0 5rem", width: "300px" }
          }
          paragraphText={t("homePagefirstSection.headerParagarph")}
          imgName={[multiAI]}
          twoButtons={{
            button1: {
              icon: webAppIcon,
              onClickMethod: undefined,
              title: t("StartFreeButton.UseOnWeb"),
            },
            button2: {
              icon: whiteWhatsAppIMG,
              onClickMethod: handleOpenWhatsAppModal,
              title: t("StartFreeButton.UseOnWhatsApp"),
            },
          }}
        />
        <VideoSection />
        <StartFreeAndImg
          containerStyles={{
            alignItems: "flex-end",
            flexDirection: isBelowThreshold ? "column" : "",
          }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStyles["he"]
                    : buttonAndLabelStyles["en"]),
                  flexDirection: "column",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStyles["he"]
              : buttonAndLabelStyles["en"]
          }
          buttonStyles={isBelowThreshold ? { margin: "5rem 0 0" } : {}}
          imgStyle={{ height: "100%", width: "100%", maxWidth: "700px" }}
          paragraphText={t("homePageSecondSection.StartFreeAndImg")}
          imgName={[unicornSVG]}
          negative
        />
        <StartFreeAndImg
          containerStyles={{ flexDirection: isBelowThreshold ? "column" : "" }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStyles["he"]
                    : buttonAndLabelStyles["en"]),
                  flexDirection: "column",
                  marginBottom: "4rem",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStyles["he"]
              : buttonAndLabelStyles["en"]
          }
          imgStyle={{ height: "100%", width: "100%", maxWidth: "1000px" }}
          paragraphText={t("ClaudeSection.headerParagarph")}
          imgName={[claudeImage]}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : {}}
          reverse
          negative
        />
        <StartFreeAndImg
          // buttonAndLabelStyles={buttonAndLabelStyles[String(recoilLangModel).slice(0, 2) as keyof LanguageStyles]}
          containerStyles={{ flexDirection: isBelowThreshold ? "column" : "" }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStyles["he"]
                    : buttonAndLabelStyles["en"]),
                  flexDirection: "column",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStyles["he"]
              : buttonAndLabelStyles["en"]
          }
          imgStyle={{ height: "100%", width: "100%" }}
          paragraphText={t("GPT-4Section.headerParagarph")}
          imgName={[gpt4Motion]}
          // buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : {}}
        />
        <StartFreeAndImg
          // buttonAndLabelStyles={buttonAndLabelStyles[String(recoilLangModel).slice(0, 2) as keyof LanguageStyles]}
          containerStyles={{ flexDirection: isBelowThreshold ? "column" : "" }}
          buttonAndLabelStyles={
            isBelowThreshold
              ? {
                  ...(recoilLangModel.includes("he")
                    ? buttonAndLabelStyles["he"]
                    : buttonAndLabelStyles["en"]),
                  flexDirection: "column",
                }
              : recoilLangModel.includes("he")
              ? buttonAndLabelStyles["he"]
              : buttonAndLabelStyles["en"]
          }
          imgStyle={{ height: "100%", width: "70%" }}
          paragraphText={t("WhatsAppSection.headerParagarph")}
          imgName={[whatsAppAndChatExample]}
          // buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : {}}
          reverse
          negative
          onClickMethod={handleOpenWhatsAppModal}
        />
        {/* <WhatsappExamplesSection
          // firstMessage={whatsAppExampleFirstMessage}
          // secondMessage={whatsAppExampleSecondMessage}
          buttonAndLabelStyles={(recoilLangModel.includes("he") ? buttonAndLabelStylesWhatsAppSection["he"] : buttonAndLabelStylesWhatsAppSection["en"])}
          // buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          title={t("WhatsAppSection.headerParagarph")}
          photo={whatsAppAndChatExample}
          imgStyles={{ width: 'unset' }}
        /> */}
        {/* <div id="GPT4" className={Styles.HomePageSecondAndThirdRowItemContainer}>
        <div className={Styles.ThirdRowWhatsappChatContainer}>
          <span>
            <span>9:41</span>
            <img
              src={require("../../assets/homePageAssets/whatsappIconTurquoise.png")}
              alt="WhatsAppIcon"
            />
            <img
              src={require("../../assets/homePageAssets/Right option.png")}
              alt=""
            />
          </span>
          <div className={Styles.WhatsAppChat}>
            <p>
              Act as my copywriter and write me an SEO-driven article on DSLR
              cameras{" "}
            </p>
            <div>
              <p>
                Sure,
                <br />
                Title: "Unlocking the Power of DSLR Cameras: A Guide for
                Photography Enthusiasts" In the realm of photography, the
                Digital Single-Lens Reflex (DSLR) camera reigns supreme.
                <br />
                <br />
                Unparalleled in its versatility and image quality, it has become
                the go-to gadget for professional photographers and hobbyists
                alike. But what is it about DSLR cameras that set them apart in
                a crowded market?...
              </p>
            </div>
          </div>
        </div>
        <div className={Styles.ThirdRowStartFreeDiv}>
          <p>Harness the power of Chat GPT-4</p>
          <StartFreeButton
            title="Chat For free"
            negative
            backgroundColor="#181818"
          />
        </div>
      </div> */}
        <div id="about" className={Styles.ForthRowFiguresContainer}>
          <p>{t("homePageFourthSection.p")}</p>
          <div className={Styles.FiguresContainer}>
            {figuresData.map((figure: any) => {
              return (
                <div key={`${figure.data}_${Math.random() * 50}`}>
                  <FiguresCards
                    asset={figure.asset}
                    number={figure.number}
                    data={figure.data}
                    symbol={figure.symbol}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <PlanCardsSection />
        <div className={Styles.FifthRowMainContainer}>
          <p
            className={
              recoilLangModel.toLocaleLowerCase().includes("he")
                ? Styles.HebClass
                : ""
            }
          >
            {t("homePageFifthSection.p")}
          </p>
          <StartFreeButton
            title={t("StartFreeButton.StartForFree")}
            negative
            customStyle={{ backgroundColor: "#FD4766", border: "none" }}
          />
          <ServicesCompare />
          <div className={Styles.ServiceDataCardsContainer}>
            {serviceDataCardsInfo.map((cardData: ServiceDataCardsInterface) => {
              return (
                <ServiceDataCard
                  key={cardData.headerTitle}
                  imgSource={cardData.imgSource}
                  headerTitle={cardData.headerTitle}
                  paragraphText={cardData.paragraphText}
                />
              );
            })}
          </div>
        </div>
        <FaqSection />
        {/* <div id="faq" className={Styles.SeventhRowMainContainer}>
        <p>Learn More About Me Through Answered FAQs</p>
        <AboutAccordion accordionsArr={aboutAccordionsData} />
      </div> */}
        {/* <div className={Styles.SeventhRowAboutInfoCardContainer}>
          {infoCards.map((infoCard: AboutInfoCardInterface, index: number) => {
            return (
              <div key={`${infoCard.header}_${index}`}>
                <AboutInfoCard
                  info={infoCard.info}
                  details={infoCard.details}
                  icon={infoCard.icon}
                  header={infoCard.header}
                />
              </div>
            );
          })}
        </div> */}
        {/* <div className={Styles.EighthRowMainContainer}>
          <div
            id="midjourney"
            className={Styles.HomePageSecondAndThirdRowItemContainer}
          >
            <img
              className={Styles.SimpleImg}
              src={require("../../assets/homePageAssets/simple.png")}
              alt=""
            />
            <div className={`${Styles.SecondRowStartFreeDiv} ${recoilLangModel.toLocaleLowerCase().includes('he') ? Styles.HebClass : ""}`}>
              <p>{t('homePageEighthSection.p')}</p>
              <StartFreeButton title={t('StartFreeButton.StartForFree')} negative />
            </div>
          </div>
        </div> */}
        {/* <div> */}
        <UniContact />
        {/* </div> */}
        {openWhatsAppModal && (
          <LetsChatModal chatModalOpenState={handleOpenWhatsAppModal} />
        )}
      </div>
    </>
  );
}
