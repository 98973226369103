import React, { useEffect, useRef, useState } from "react";
import Styles from "./LetsChatModal.module.scss";
import StartFreeButton from "../StartFreeButton/StartFreeButton.cmp";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useRecoilState, useRecoilValue } from "recoil";
import { openLetsChatModal } from "../../store/atoms/contactUsAtom";
import SelectInput from "../SelectInput/SelectInput.cmp";
import { storage, redirectFunctions, extractUserData, getAllUTMData } from "../../utils/utils.service";
import axios from "axios";
import LoaderSuccErr from "../../utils/UtilCmps/LoaderSuccErr/LoaderSuccErr.cmp";

// Phone input import
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import { WA_LINK } from "../../utils/constant";
import { useTranslation } from "react-i18next";
import { languageModel } from "../../store/atoms/generalAtoms";

interface LetsChatModalProps {
  chatModalOpenState?: () => void;
}

export default function LetsChatModal({ chatModalOpenState }: LetsChatModalProps) {
  const [closeLetsChatModal, setCloseLetsChatModal] =
    useRecoilState(openLetsChatModal);
  // const [selectInputPrefixes, setSelectInputPrefixes] = useState<string[]>(["972", "908"])
  // const [chosenPrefix, setChosenPrefix] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [validationError, setValidationError] = useState<boolean>(false);
  const [startLoader, setStartLoader] = useState<boolean>(false);
  const [showContainer, setShowContainer] = useState<boolean>(false);
  const [respStatus, setRespStatus] = useState<boolean>();
  const [t, i18n] = useTranslation();
  // const phoneNumberRef = useRef("");
  const recoilLangModel = useRecoilValue(languageModel);

  // Update the ref whenever phoneNumber changes
  useEffect(() => {
    // phoneNumberRef.current = phoneNumber;
    if (validationError && phoneNumber) {
      setValidationError(false);
    }
  }, [phoneNumber]);

  // Validation function that utilizes the isValidPhoneNumber of react-phone-number package to validate if its a real phone number.
  // const sendDataValidate = (): boolean => {
  //   // console.log(isValidPhoneNumber(phoneNumber), phoneNumber)
  //   if (isValidPhoneNumber(phoneNumberRef.current)) {
  //     console.log("🚀 ~ file: LetsChatModal.cmp.tsx:48 ~ sendDataValidate ~ phoneNumber:", phoneNumber)
  //     if (validationError) {
  //       setValidationError(false);
  //     }
  //     return true;
  //   }
  //   setValidationError(true);
  //   return false;
  // };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      // Call sendDataToDB when Enter is pressed
      event.preventDefault();
      sendDataToDB();
    }
  };

  // useEffect(() => {
  //   // Add the event listener when the component mounts
  //   window.addEventListener("keydown", handleKeyPress);

  //   // Remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);


  // Function that onClick orginizes the data from localstorage and sends it to the server.
  const sendDataToDB = async () => {
    const isPhoneValid = isValidPhoneNumber(phoneNumber);

    // validation if the number is an actual phone number.
    if (!isPhoneValid) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
    }
    // Gets the data from LocalStorage, sends it by url params to the api, and waits for the response to update the loader status icon.
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: "sign_up",
      user_id: phoneNumber.replace(/^\+/, ""),
    });
    (window as any).fbq('track', 'CompleteRegistration');
    // console.log(phoneNumberRef.current)
    try {
      const _fbp = extractUserData.getFBP();
      setStartLoader(true);
      const clientFullData = storage.loadFromStorage("clientFullData");
      const utm = getAllUTMData(phoneNumber, "whatsApp")

      let params = {
        userPhone: phoneNumber,
        ...(_fbp ? { fbp: _fbp } : {}),
        ...(clientFullData.fbclid ? { fbclid: clientFullData.fbclid } : {}),
        ...(clientFullData.gaClientId
          ? { gaClientId: clientFullData.gaClientId }
          : {}),
        // ...(clientFullData.utm_source
        //   ? { utm_source: clientFullData.utm_source }
        //   : {}),
        // ...(clientFullData.utm_medium
        //   ? { utm_medium: clientFullData.utm_medium }
        //   : {}),
        // ...(clientFullData.utm_campaign
        //   ? { utm_campaign: clientFullData.utm_campaign }
        //   : {}),
        ...utm
      };
      // console.log("🚀 ~ file: LetsChatModal.cmp.tsx:109 ~ sendDataToDB ~ params:", params)
      // console.log(params)
      const resp = await axios.get(
        `${process.env.REACT_APP_ENV}/api/chatbot/start-new-chat`,
        { params }
      );
      // console.log(resp, params);
      resp.status === 200 ? setRespStatus(true) : setRespStatus(false);
      // setRespStatus(true)
      // setRespStatus(false)
      setTimeout(() => {
        window.open(`${WA_LINK}`, "_blank");
        // setCloseLetsChatModal(false);
        chatModalOpenState!()
      }, 2000);
    } catch (err) {
      setRespStatus(false);
      console.log(err);
    }
  };

  useEffect(() => {
    setShowContainer(true);
  }, []);
  // useEffect(() => {
  //     console.log(phoneNumber)

  // }, [phoneNumber])
  return (
    <div
      className={Styles.LetsChatBackDrop}
      // onClick={() => setCloseLetsChatModal(false)}
      onClick={() => chatModalOpenState!()}
    >
      <div
        className={`${Styles.LetsChatModalContainer} ${showContainer ? Styles.ShowChatContainer : ""
          } ${recoilLangModel.toLocaleLowerCase().includes("he")
            ? Styles.HebClass
            : ""
          }`}
        onClick={(ev: React.MouseEvent) => ev.stopPropagation()}
      >
        <span>{t("LetsChatModal.headerSpan")}</span>
        <p>{t("LetsChatModal.p")}</p>
        {/* <span className={Styles.PhoneNumberInput} style={{ border: validationError ? "1px solid red" : "" }}> */}
        {/* <span>
                        <label htmlFor='phoneNumber'>+972</label>
                        <SelectInput id='area-code-select' Label='' items={selectInputPrefixes} onChange={chosePrefix} value={chosenPrefix} />
                    </span>
                    <input type='number' name='phoneNumber' title='phoneNumber' placeholder='Enter Your Phone Number' onChange={(e) => setPhoneNumber(e.target.value)} /> */}

        <div className={Styles.PhoneNumberInput}>
          <PhoneInput
            defaultCountry={
              !recoilLangModel.toLocaleLowerCase().includes("he") ? "US" : "IL"
            }
            onKeyUp={handleKeyPress}
            // placeholder={`${!recoilLangModel.toLocaleLowerCase().includes("he") ? "Enter phone number" : "הכנס מספר פלאפון"}`}
            value={phoneNumber}
            withCountryCallingCode
            international
            onChange={(e) => { setPhoneNumber(e) }}
            rules={{ required: true }}
            className={Styles.PhoneInput}
            // smartCaret
            // countries={["IL"]}
            addInternationalOption
          />
        </div>
        {
          <span
            className={`${Styles.ValidationErrorMsg} ${!validationError ? Styles.VisibilityHidden : ""
              }`}
          >
            {t("LetsChatModal.validationError")}
          </span>
        }

        {/* </span> */}
        {startLoader ? (
          <LoaderSuccErr
            isLoading={startLoader}
            responseStatus={respStatus}
            afterBackgroundColor="black"
            loaderStyles={{ marginBottom: "3.4rem" }}
          />
        ) : (
          <StartFreeButton
            title={t("StartFreeButton.StartChat")}
            customStyle={{
              color: "white",
              backgroundColor: "#10B9AA",
              border: "none",
              margin: "0 0 3rem",
            }}
            negative
            onClickMethod={sendDataToDB}
          />
        )}
        <span
          className={Styles.CloseLetsChatModalModalButton}
          // onClick={() => setCloseLetsChatModal(false)}
          onClick={() => chatModalOpenState!()}
          style={{ cursor: "pointer" }}
        >
          <ClearRoundedIcon fontSize="large" />
        </span>
      </div>
    </div>
  );
}
