import React, { useEffect, useState } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { enUS, heIL } from '../../assetsImports'
import styles from './LangSelector.module.scss'
import i18n from "../../i18n";
import { FormControl } from '@mui/material';
import { languageModel } from '../../../store/atoms/generalAtoms';
import { useRecoilState, useSetRecoilState } from 'recoil';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const languages: any[] = [{ lang: "en-US", flag: enUS }, { lang: "he-IL", flag: heIL }]
export default function LangSelector() {
    const setRecoilLangModel = useSetRecoilState(languageModel)

    // console.log("🚀 ~ file: LangSelector.cmp.tsx:27 ~ LangSelector ~ i18n.language:", `${i18n.language.slice(0, 2)}${i18n.language.slice(3)}`)
    const [lang, setLang] = useState<any>({ lang: i18n.language, flag: `${i18n.language.slice(0, 2)}${i18n.language.slice(3)}` });

    const changeLanguage = async (language: string, fullObject: any) => {
        // console.log(fullObject, language)
        setLang(fullObject)
        await i18n.changeLanguage(language)
        setRecoilLangModel(language)
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set("lang", language);
        window.history.pushState({}, "", currentUrl.toString());
    }

    function LangSelectComponent() {
        return (<FormControl>
            <Select
                className={`${styles.languageSelectInput} ${lang.lang.includes("he") ? styles.rtlLang : ""}`}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang.lang}

                onChange={(e: SelectChangeEvent) => {
                    const selectedLanguage = languages.find(l => l.lang === e.target.value);
                    if (selectedLanguage) {
                        changeLanguage(e.target.value, selectedLanguage);
                    }
                }}
                renderValue={(selectedValue) => {
                    const selectedLang = languages.find(l => l.lang === selectedValue);
                    if (selectedLang) {
                        return <img src={selectedLang.flag} style={{ height: "15px", width: "25px" }} alt={`${selectedLang.lang} flag`} />;
                    }
                    return null;
                }}

            >
                {languages.map((language: any) => {
                    return <MenuItem key={language.lang} value={language.lang} style={{ display: "flex", justifyContent: "center" }}><img src={language.flag} style={{ height: "15px", width: "25px" }} alt={`${language.lang} flag`}></img></MenuItem>
                })}
            </Select>
        </FormControl>)
    }

    return (
        <div >
            {lang &&
                (lang.lang.includes("he")
                    ? (
                        <CacheProvider value={cacheRtl}>
                            <LangSelectComponent />
                        </CacheProvider>
                    )
                    : <LangSelectComponent />
                )
            }
        </div>
    )
}
