import React, { useEffect, useLayoutEffect } from "react";
import styles from "./ThankYouPage.module.scss";
import { WA_LINK } from "../../utils/constant";
import { vSign } from "../../utils/assetsImports";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  languageModel,
  redirectedToThankYouPage,
} from "../../store/atoms/generalAtoms";
import { useTranslation } from "react-i18next";
import { storage } from "../../utils/utils.service";
import { whiteWhatsAppIMG } from "../../utils/assetsImports";
import { GlobeIcon } from "lucide-react";

const ThankYouPage = () => {
  const [thankYouPage, setThankYouPage] = useRecoilState<boolean>(
    redirectedToThankYouPage
  );
  const [t, i18n] = useTranslation();
  const recoilLangModel = useRecoilValue(languageModel);

  useEffect(() => {
    const user_id = storage.loadFromStorage("userId");
    const user_email = storage.loadFromStorage("userEmail");
    const user_phone = storage.loadFromStorage("userPhone");
    const dataLayerCheckOutData = storage.loadFromStorage(
      "dataLayerCheckOutData"
    );
    (window as any).dataLayer.push({
      event: "purchase",
      user_id,
      user_connection: user_email ? user_email.toLocaleLowerCase()! : null,
      user_phone: user_phone,
    });
    (window as any).fbq("track", "Subscribe", {
      value: dataLayerCheckOutData?.items?.price,
      currency: dataLayerCheckOutData?.items?.currency,
      predicted_ltv: "3",
    });
    setThankYouPage(true);
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const negishutElement = document.querySelector(
            ".accessibility_component.accessibility_div_wrap"
          ) as HTMLElement;
          if (negishutElement) {
            negishutElement.style.display = "none";
          }
        }
      });
    });

    // Observe the entire document. Adjust the target and options as necessary for your use case.
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();

      // Your existing cleanup logic
      setThankYouPage(false);
      const negishutElement = document.querySelector(
        ".accessibility_component.accessibility_div_wrap"
      ) as HTMLElement;
      if (negishutElement) {
        negishutElement.style.display = "inherit";
      }
    };
  }, []);

  return (
    <div
      className={styles.thankYouPageMainContainer}
      style={{ direction: recoilLangModel.includes("he") ? "rtl" : "ltr" }}
    >
      <div className={styles.thankYouPageDataContainer}>
        <img src={vSign} alt="v sign" />
        <h1>{t("ThankYouPage.Header")}</h1>
        <p>
          <span>{t("ThankYouPage.p.span1")}</span>
          <span>{t("ThankYouPage.p.span2")}</span>
          {/* <span>{t("ThankYouPage.p.span3")}</span> */}
        </p>
        <section className={styles.buttonContainer}>
          <button className={styles.thankYouPageButton}>
            <a href={WA_LINK} target="_blank" rel="noopener noreferrer">
              {t("ThankYouPage.ConnectionLinks.WhatsApp")}
            </a>
            <img src={whiteWhatsAppIMG} alt="whatsapp" />
          </button>
          <button className={styles.thankYouPageButtonAlternate}>
            <a
              href={`${process.env.REACT_APP_CHAT_URL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("ThankYouPage.ConnectionLinks.Web")}
            </a>
            <GlobeIcon width={30} height={30} strokeWidth={1.3} />
          </button>
        </section>
      </div>
    </div>
  );
};

export default ThankYouPage;
