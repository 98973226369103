import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Styles
import "animate.css";
import Styles from "./MidJourney.module.scss";
// CMPS
import StartFreeAndImg from "../../PageSections/StartFreeAndImg/StartFreeAndImg.cmp";
// Assets
// import { vanGochLargeImg, vanGochSecondImg, vanGochThirdImg, vanGochForthImg, vanGochSeventhImg, vanGochSixthImg, vanGochForthImg1X, vanGochSixthImg2X } from '../../utils/assetsImports'
import {
  heroImg,
  FirstVars,
  Variants,
  selected,
  zoom2x,
  vanGochForthImg,
  vanGochSeventhImg,
  vanGochSixthImg,
  vanGochForthImg1X,
  vanGochSixthImg2X,
  vary,
  zoom1x,
  whatsAppMidJDemo
} from "../../utils/assetsImports";

import PlanCardsSection from "../../PageSections/PlanCardsSection/PlanCardsSection.cmp";
import FaqSection from "../../PageSections/FaqSection/FaqSection.cmp";
import WhatsappExamplesSection from "../../PageSections/WhatsappExamplesSection/WhatsappExamplesSection.cmp";
import ExampleImgCard, {
  ExampleImgCardType,
} from "../../cmps/ExampleImgCard/ExampleImgCard.cmp";
import { getExampleCardsData } from "../../utils/HardCodedData";
import UniContact from "../../cmps/UniContact/UniContact.cmp";
import StartFreeButton from "../../cmps/StartFreeButton/StartFreeButton.cmp";
import UniFooter from "../../cmps/UniFooter/UniFooter.cmp";
import { checkToken, extractUserData, storage } from "../../utils/utils.service";
import { useRecoilState, useRecoilValue } from "recoil";
import { clientPhoneNumber } from "../../store/atoms/contactUsAtom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { languageModel } from "../../store/atoms/generalAtoms";
import { buttonAndLabelStyles, buttonAndLabelStylesWhatsAppSection } from "../../utils/constant";

const WhatsAppExampleFirstMessage: string = `Imagine Vincent van Gogh as a child`;
const WhatsAppExampleSecondMessage = <img src={Variants} alt="" />;



export default function MidJourneyPage() {
  const navigate = useNavigate();
  const [exampleImgCardsData, setExampleImgCardsData] =
    useState<ExampleImgCardType[]>(getExampleCardsData());
  const [userPhone, setUserPhone] = useRecoilState(clientPhoneNumber)
  const recoilLangModel = useRecoilValue(languageModel)
  const [t, i18n] = useTranslation()
  const [isBelowThreshold, setIsBelowThreshold] = useState(window.innerWidth < 1378);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set whether screen width is below threshold
      setIsBelowThreshold(window.innerWidth < 1378);
    }

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Detach event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  // Gets clients data from url params as soon as he lands in that page
  useEffect(() => {
    // checkToken(navigate)
    // Gets the data from the url params
    const clientUrlParams = extractUserData.extractUrlParams()
    // Gets the data from the cookies
    const gaClientId = extractUserData.getGAClientId()
    // If the params contains userPhone, it will set a global state with it and save it to local storage.
    let urlUserPhone: string = extractUserData.extractUrlPhoneNumber().userPhone || '';
    if (urlUserPhone !== "") {
      // console.log(urlUserPhone)
      storage.saveToStorage("userPhone", `+${urlUserPhone}`);
      setUserPhone(`+${urlUserPhone}`)
    }

    // Saves all the data as an object to local storage.
    storage.saveToStorage("clientFullData", { ...clientUrlParams, gaClientId })
  }, [])



  return (
    <>
      <Helmet>
        <title>{t('midjourneyPage.helmet.title')}</title>
        <meta name="description" content={t('midjourneyPage.helmet.description')}></meta>
      </Helmet>

      <div
        id="/Midjourney"
        title="/Midjourney"
        className={`${Styles.UniMidJourneyPageMainContainer} animate__animated animate__fadeIn`}
      >
        <StartFreeAndImg
          buttonAndLabelStyles={(recoilLangModel.includes("he") ? buttonAndLabelStyles["he"] : buttonAndLabelStyles["en"])}
          imgStyle={{ maxHeight: "100%", maxWidth: "100%" }}
          paragraphText={t('midjourneyPage.StartFreeAndImg1')}
          imgName={[heroImg]}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
        />
        <StartFreeAndImg
          buttonAndLabelStyles={(recoilLangModel.includes("he") ? buttonAndLabelStyles["he"] : buttonAndLabelStyles["en"])}
          // imgStyle={{ padding: "3.5rem" }}
          paragraphText={t('midjourneyPage.StartFreeAndImg2')}
          imgName={[FirstVars]}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          reverse
        />
        <StartFreeAndImg
          buttonAndLabelStyles={(recoilLangModel.includes("he") ? buttonAndLabelStyles["he"] : buttonAndLabelStyles["en"])}
          // imgStyle={{ padding: "3.5rem" }}
          paragraphText={t('midjourneyPage.StartFreeAndImg3')}
          imgName={[Variants]}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
        />
        <StartFreeAndImg
          buttonAndLabelStyles={(recoilLangModel.includes("he") ? buttonAndLabelStyles["he"] : buttonAndLabelStyles["en"])}
          paragraphText={t('midjourneyPage.StartFreeAndImg4')}
          imgName={[selected]}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          reverse
        />
        <StartFreeAndImg
          imgStyle={{ maxHeight: "80%", alignSelf: "center" }}
          paragraphText={t('midjourneyPage.StartFreeAndImg5')}
          imgName={[zoom1x, zoom2x]}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          imagesContainerStyles={{ margin: "5rem auto 0" }}
          column
        />
        <StartFreeAndImg
          // imgStyle={{ padding: "3.5rem" }}
          buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          paragraphText={t('midjourneyPage.StartFreeAndImg6')}
          imgName={[vary]}
        />
        <WhatsappExamplesSection
          // firstMessage={WhatsAppExampleFirstMessage}
          // secondMessage={WhatsAppExampleSecondMessage}
          buttonAndLabelStyles={(recoilLangModel.includes("he") ? buttonAndLabelStylesWhatsAppSection["he"] : buttonAndLabelStylesWhatsAppSection["en"])}
          // buttonStyles={!isBelowThreshold ? { margin: "5rem 0 0" } : { margin: "0 0 5rem" }}
          title={t('midjourneyPage.WhatsAppExampleSection')}
          photo={whatsAppMidJDemo}
        />

        <PlanCardsSection toggleButtonNegative={true} />
        <div>
          <FaqSection />
          <div className={Styles.ShowcaseTitle}>
            <p>{t('midjourneyPage.CommunityShowCase.header')}</p>
          </div>
          <div className={Styles.ExampleCardsSectionContainer}>
            {exampleImgCardsData.map(
              (imgCard: ExampleImgCardType, index: number) => {
                return (
                  <div key={`${imgCard.title}_${index}`}>
                    <ExampleImgCard
                      img={imgCard.img}
                      title={imgCard.title}
                      description={imgCard.description}
                    />
                  </div>
                );
              }
            )}
          </div>
          <UniContact />
        </div>
      </div>
    </>
  );
}
