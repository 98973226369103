import React, { useEffect, useState } from "react";
import Styles from "./UniContact.module.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import { openLetsChatModal } from "../../store/atoms/contactUsAtom";
import { unicornContactIcon, unichatWebSiteNewLogo } from "../../utils/assetsImports";
import UniFooter from "../UniFooter/UniFooter.cmp";
import LoaderSuccErr from "../../utils/UtilCmps/LoaderSuccErr/LoaderSuccErr.cmp";
import { chatLink, getURI } from "../../utils/constant";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { isP0FreeRegion, languageModel } from "../../store/atoms/generalAtoms";
import { extractUserData, storage } from "../../utils/utils.service";

type contactMeData = {
  name: string;
  email: string;
  msg: string;

};
interface UniContactProps {
  scrollToTop?: boolean;
}
export default function UniContact({ scrollToTop }: UniContactProps) {
  const [openChatModal, setOpenChatModal] = useRecoilState(openLetsChatModal);
  const [contactMessage, setContactMessage] = useState<contactMeData>({
    name: "",
    email: "",
    msg: "",
  });
  const [errorFlags, setErrorFlags] = useState<any>({
    name: false,
    email: false,
    msg: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [respStatus, setRespStatus] = useState<boolean>();
  const [t, i18n] = useTranslation()
  const recoilLangModel = useRecoilValue(languageModel)
  const user_email = storage.loadFromStorage("userEmail")
  const user_id = storage.loadFromStorage("userId")
  const isFreeRegion = useRecoilValue<boolean>(isP0FreeRegion);
  const [utmDataString, setUtmDataString] = useState<string>("")

  const sendEmail = () => {
    const { email, name, msg } = contactMessage;
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(name)}&body=${encodeURIComponent(msg)}`;
  };
  // Validation function that validate each property of the contact message thats about to be sent and turns on and off the error flags.
  const validateContactData = () => {
    // Validates if the string looks like an email
    const emailRegex: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let flag: boolean = false;
    if (contactMessage.name === "") {
      setErrorFlags((prevValue: any) => ({ ...prevValue, name: true }));
      flag = true;
    } else {
      setErrorFlags((prevValue: any) => ({ ...prevValue, name: false }));
    }
    if (!emailRegex.test(contactMessage.email)) {
      setErrorFlags((prevValue: any) => ({ ...prevValue, email: true }));
      flag = true;
    } else {
      setErrorFlags((prevValue: any) => ({ ...prevValue, email: false }));
    }
    if (contactMessage.msg === "") {
      setErrorFlags((prevValue: any) => ({ ...prevValue, msg: true }));
      flag = true;
    } else {
      setErrorFlags((prevValue: any) => ({ ...prevValue, msg: false }));
    }
    return flag;
  };

  const sendContactMessageToServer = async () => {
    // if there is no validation (return true) the function will exit.
    if (validateContactData()) {
      return;
    }

    setIsLoading(true);
    try {
      const uri = process.env.REACT_APP_ENV;
      const resp = await axios.post(`${uri}/contact-form`, contactMessage);
      // const resp = { status: 200 }
      if (resp.status === 200) {
        setRespStatus(true);
        // setTimeout(() => {
        //   setIsLoading(false)
        // }, 2000)
      }
    } catch (err) {
      setRespStatus(false);
      console.log(err);
      // setTimeout(() => {
      //   setIsLoading(false)
      // }, 2000)
    }
  };

  useEffect(() => {
    if (isFreeRegion) {
      const { utm_campaign, utm_medium, utm_source } = extractUserData.extractUrlParams()
      if (utm_campaign || utm_medium || utm_source) {
        setUtmDataString(`&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`)
      }
    }
  }, [])

  const scrollToTopDiv = () => {
    const upperDiv = document.getElementById("midjourney");
    if (upperDiv) {
      upperDiv.scrollIntoView({ behavior: "smooth", block: "start" });

    }
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div id="contact" className={Styles.UniContactMainContainer}>
        <div className={`${Styles.ContactInfoContainer} ${recoilLangModel.toLocaleLowerCase().includes('he') ? Styles.HebClass : ""}`}>
          <img src={unichatWebSiteNewLogo} alt="unicornContactIcon" />
          <span className={Styles.ConcatInfoHeader}>{t('UniContact.infoHeader')}</span>
          {/* <p>About Unichat</p> */}
          <p className={Styles.ContactInfoDescription}>
            {t('UniContact.infoDescription')}
          </p>
          {!scrollToTop ? <a
            className={Styles.ContactInfoLetsChatButton}
            href={isFreeRegion ? `${process.env.REACT_APP_CHAT_URL}/login?p0=true${utmDataString}` : `${process.env.REACT_APP_CHAT_URL}/login?${utmDataString}`}
          >
            {t('UniContact.LetsChatButton')}
          </a>
            :
            <button className={Styles.ContactInfoLetsChatButton} onClick={scrollToTopDiv}>{t('UniContact.LetsChatButton')}</button>}
          {/* <p>Any Question?</p> */}
          {/* <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus tempora
          ratione quas tempore quaerat, vitae enim quis laborum et.
        </p> */}
          {/* <div className={Styles.ContactOptionsAndInfoContainer}>
          <span className={Styles.ContactInfoSpan}>
            <img
              src={require("../../assets/map-marker-alt.png")}
              alt="MapMarker"
            />
            <p>Ta-134/A, Gulshan Badda Link Rd, Nya 10982 USA </p>
          </span>
          <span className={Styles.ContactInfoSpan}>
            <img src={require("../../assets/envelope.png")} alt="envelope" />
            <div>
              <p>Rasalinawillam@gmail.com</p>
              <p>infoweb@gmail.com</p>
            </div>
          </span>
          <span className={Styles.ContactInfoSpan}>
            <img src={require("../../assets/Phone.png")} alt="Phone" />
            <div>
              <p>+08 98787 8773 345</p>
              <p>+887 998 67563 345</p>
            </div>
          </span>
        </div> */}
        </div>
        <div id="contact" className={`${Styles.ContactUsContainer} ${recoilLangModel.toLocaleLowerCase().includes('he') ? Styles.HebClass : ""}`}>
          <p>{t('UniContact.ContactUs.p1')}</p>
          <p>{t('UniContact.ContactUs.p2')}</p>
          <div className={Styles.ContactUsLabelAndInputContainer}>
            <label htmlFor="Name">{t('UniContact.ContactUs.nameInputLabel')}</label>
            <input
              type="text"
              name="Name"
              title="Name"
              style={{ borderColor: errorFlags.name ? "red" : "" }}
              onChange={(e) =>
                setContactMessage((prevValue: contactMeData) => ({
                  ...prevValue,
                  name: e.target.value,
                }))
              }
            />
          </div>
          <div className={Styles.ContactUsLabelAndInputContainer}>
            <label htmlFor="Email">{t('UniContact.ContactUs.emailInputLabel')}</label>
            <input
              type="text"
              name="Email"
              title="Email"
              style={{ borderColor: errorFlags.email ? "red" : "" }}
              onChange={(e) =>
                setContactMessage((prevValue: contactMeData) => ({
                  ...prevValue,
                  email: e.target.value,
                }))
              }
            />
          </div>
          <div className={Styles.ContactUsLabelAndInputContainer}>
            <label htmlFor="EnterYourMessage">{t('UniContact.ContactUs.messageInputLabel')}</label>
            <textarea
              name="EnterYourMessage"
              title="Enter Your Message"
              rows={5}
              style={{ borderColor: errorFlags.msg ? "red" : "" }}
              onChange={(e) =>
                setContactMessage((prevValue: contactMeData) => ({
                  ...prevValue,
                  msg: e.target.value,
                }))
              }
            ></textarea>
          </div>
          {isLoading ? (
            <LoaderSuccErr
              isLoading
              responseStatus={respStatus}
              afterBackgroundColor="#141515"
            />
          ) : (
            <button
              className={Styles.ContactUsSendMessageButton}
              onClick={sendContactMessageToServer}
            // onClick={sendEmail}
            >
              {t('UniContact.ContactUs.sendMessageButton')}
            </button>
          )}
        </div>
      </div>
      <UniFooter />
    </div>
  );
}
