import React, { useEffect, useState } from "react";
import Styles from "./BillindModal.module.scss";
import { openBillingModal } from "../../store/atoms/pricingPlanAtom";
import MuiStepper from "../../utils/UtilCmps/MuiStepper/MuiStepper.cmp";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
// React phone input
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
// Recoil import
import { useRecoilState, useRecoilValue } from "recoil";
import { clientPhoneNumber } from "../../store/atoms/contactUsAtom";
import { billingIframeUrl } from "../../store/atoms/pricingPlanAtom";
// React router
import { useLocation } from "react-router-dom";
import LoaderSuccErr from "../../utils/UtilCmps/LoaderSuccErr/LoaderSuccErr.cmp";
import { useTranslation } from "react-i18next";
import { languageModel } from "../../store/atoms/generalAtoms";

interface BillingModalProps {
  setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>;
  isWhatsApp?: boolean;
}

export default function BillindModal({
  setOpenBillingModal,
  isWhatsApp,
}: BillingModalProps) {
  const [t, i18n] = useTranslation();
  const [billingModalOpenState, setBillingModalOpenState] =
    useRecoilState(openBillingModal);
  const [showContainer, setShowContainer] = useState<boolean>(false);
  const [stepperCmps, setStepperCmps] = useState<Array<any>>([
    { title: t("BillingModal.MuiStepper.step1"), cmp: InsertWhatsAppNumber },
  ]);
  // const [stepperCmps, setStepperCmps] = useState<Array<any>>([{ title: t('BillingModal.MuiStepper.step1'), cmp: InsertWhatsAppNumber }, { title: t('BillingModal.MuiStepper.step2'), cmp: BillingIframe }])
  const [phoneNumber, setPhoneNumber] = useRecoilState(clientPhoneNumber);
  const recoilLangModel = useRecoilValue(languageModel);

  const location = useLocation();

  useEffect(() => {
    setShowContainer(true);
  }, []);

  // Phone input component to receive the user whatsapp number
  function InsertWhatsAppNumber() {
    const [phoneNumber, setPhoneNumber] =
      useRecoilState<any>(clientPhoneNumber);
    const [validationError, setValidationError] = useState<boolean>(false);

    return (
      <div className={Styles.PhoneNumberForm}>
        <span className={Styles.PhoneNumberFormTitle}>
          {t("BillingModal.phoneInputHeader")}
        </span>
        <PhoneInput
          defaultCountry={
            recoilLangModel.toLocaleLowerCase().includes("he") ? "IL" : "US"
          }
          placeholder="Enter phone number"
          value={phoneNumber}
          withCountryCallingCode
          international
          onChange={setPhoneNumber}
          rules={{ required: true }}
          className={Styles.PhoneInput}
        />
      </div>
    );
  }

  const onCloseModal = () => {
    setOpenBillingModal(false);
    setPhoneNumber("");
  };

  // Iframe component
  // function BillingIframe() {
  //     const [isLoading, setIsLoading] = useState<boolean>(true);
  //     const [iFrameUrl, setIframeUrl] = useRecoilState(billingIframeUrl)
  //     return (
  //         <>
  //             <span className={Styles.iFrameLoader}><LoaderSuccErr isLoading={isLoading} afterBackgroundColor="white" /></span>
  //             <iframe className={Styles.BillingIframe} title="Billing Iframe" src={iFrameUrl} onLoad={() => setIsLoading(false)}></iframe>
  //         </>
  //     )
  // }

  return (
    <div className={Styles.BillingBackDrop} onClick={onCloseModal}>
      <div
        className={`${Styles.BillingModalContainer} ${
          showContainer ? Styles.ShowChatContainer : ""
        }`}
        onClick={(ev: React.MouseEvent) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
      >
        <MuiStepper
          setOpenBillingModal={setOpenBillingModal}
          isWhatsApp={isWhatsApp}
        />
        <span
          className={Styles.CloseLetsChatModalModalButton}
          onClick={onCloseModal}
        >
          <ClearRoundedIcon sx={{ fontSize: "30px", cursor: "pointer" }} />
        </span>
      </div>
    </div>
  );
}
