import React, { useEffect } from 'react'
import styles from './ExtensionThankYou.module.scss'
import ThankYouPage from '../ThankYouPage/ThankYouPage.cmp'
import UnichatNewLogo from '../../assets/UnichatNewLogo.svg'
import { storage } from '../../utils/utils.service'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { redirectedToThankYouPage } from '../../store/atoms/generalAtoms'
const ExtensionThankYou = () => {
    const setThankYouPage = useSetRecoilState<boolean>(redirectedToThankYouPage)
    useEffect(() => {
        // const user_id = storage.loadFromStorage("userId");
        // const user_email = storage.loadFromStorage("userEmail");
        // const user_phone = storage.loadFromStorage("userPhone");
        // const dataLayerCheckOutData = storage.loadFromStorage("dataLayerCheckOutData");
        // (window as any).dataLayer = (window as any).dataLayer || [];
        // (window as any).dataLayer.push({
        //     event: "purchase",
        //     user_id,
        //     user_connection: user_email ? user_email.toLocaleLowerCase()! : null,
        //     user_phone: user_phone
        // });
        (window as any).fbq('track', 'ChromeExtensionInstall');
        setThankYouPage(true)
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.addedNodes.length) {
                    const negishutElement = document.querySelector(".accessibility_component.accessibility_div_wrap") as HTMLElement;
                    if (negishutElement) {
                        negishutElement.style.display = "none";
                    }
                }
            });
        });

        // Observe the entire document. Adjust the target and options as necessary for your use case.
        observer.observe(document.documentElement, {
            childList: true,
            subtree: true
        });

        return () => {
            observer.disconnect();

            // Your existing cleanup logic
            setThankYouPage(false);
            const negishutElement = document.querySelector(".accessibility_component.accessibility_div_wrap") as HTMLElement;
            if (negishutElement) {
                negishutElement.style.display = "inherit";
            }
        };
    }, [])
    return (
        <div className={styles.extensionThankYouPageMainContainer}>
            <section className={styles.thankYouPageContainer}>
                <img src={UnichatNewLogo} alt="" />
                <h1>Welcome To Unichat</h1>
                <p>The Unichat extension has been successfully added to your Chrome, and you may now search with AI, Google & Bing instantly from any new tab you open.</p>
                <h3>To start simply open a new tab</h3>
            </section>
        </div>
    )
}

export default ExtensionThankYou