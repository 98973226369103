import { NavigateFunction } from "react-router-dom";
import { WA_LINK } from "./constant";

// Storage object that holds 2 functions for saveing into the local storage and to load data from it.
export const storage = {
    saveToStorage(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    loadFromStorage(key: string) {
        if (key) {
            const storageValue = localStorage.getItem(key)
            return storageValue ? JSON.parse(storageValue) : null
        }
    },
    saveToSessionStorage(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value))
    },
    loadFromSessionStorage(key: string) {
        if (key) {
            const storageValue = sessionStorage.getItem(key)
            return storageValue ? JSON.parse(storageValue) : null
        }
    }
}
// Object that holds 2 functions for retrieving client data, one from Url params and the other from cookies.
export const extractUserData = {
    extractUrlParams() {
        let urlParams = new URLSearchParams(window.location.search);
        return {
            fbclid: urlParams.get("fbclid"),
            utm_source: urlParams.get("utm_source"),
            utm_medium: urlParams.get("utm_medium"),
            utm_campaign: urlParams.get("utm_campaign")
        }
    },
    getGAClientId() {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.indexOf("_ga=") === 0) {
                let clientId = cookie.substring("_ga=".length).split(".");
                return clientId[2] + "." + clientId[3];
            }
        }
        return null;
    },
    extractUrlPhoneNumber() {
        let urlParams = new URLSearchParams(window.location.search);
        return {
            userPhone: urlParams.get("userPhone")
        }
    },
    extractUrlLanguage() {
        let urlParams = new URLSearchParams(window.location.search);
        return { lang: urlParams.get("lang") }
    },
    extractUrlToken() {
        let urlParams = new URLSearchParams(window.location.search);
        return { token: urlParams.get("token") }
    },
    extractUrlUserId() {
        let urlParams = new URLSearchParams(window.location.search);
        return { user_id: urlParams.get("userId") }
    },
    extractUrlPlanId() {
        let urlParams = new URLSearchParams(window.location.search);
        return { planId: urlParams.get("planId") }
    },
    extractUrlFAQ() {
        let urlParams = new URLSearchParams(window.location.search);
        return { FAQ: urlParams.get("FAQ") }
    },
    extractUrlUserPlan() {
        let urlParams = new URLSearchParams(window.location.search);
        return { userPlan: urlParams.get("userPlan") }
    },
    getFBP() {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.indexOf("_fbp=") === 0) {
                return cookie.substring("_fbp=".length);
            }
        }
        return null;
    }
}

export const redirectFunctions = {
    redirectToWhatsApp() {
        window.open(WA_LINK, "_blank")
    }
}


interface UTMData {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
}
export const getAllUTMData = (userConnection: string, type: string): any => {
    const { fbclid, utm_source, utm_medium, utm_campaign } = extractUserData.extractUrlParams()
    const gaClientId = extractUserData.getGAClientId();
    const fbp = extractUserData.getFBP();

    // Start with an empty object for UTM data
    const utm: UTMData = {};

    // Add UTM parameters if they are not null or undefined
    if (utm_source) utm.utm_source = utm_source;
    if (utm_medium) utm.utm_medium = utm_medium;
    if (utm_campaign) utm.utm_campaign = utm_campaign;

    // Construct the base data object
    const baseData = {
        ...(gaClientId && { gaClientId }),
        ...(fbclid && { fbclid }),
        ...(fbp && { fbp }),
        ...(Object.keys(utm).length > 0 && { utm }) // Only add the utm object if it's not empty
    };

    // Return the object with either the email or userPhone property
    return type === "email"
        ? { email: userConnection, ...baseData }
        : { userPhone: userConnection, ...baseData };
    // return type === "email" ?
    //     {
    //         email: userConnection,
    //         utmData: {
    //             gaClientId,
    //             fbclid,
    //             fbp,
    //             utm: {
    //                 utm_source,
    //                 utm_medium,
    //                 utm_campaign
    //             }
    //         }
    //     }
    //     :
    //     {
    //         userPhone: userConnection,
    //         utmData: {
    //             gaClientId,
    //             fbclid,
    //             fbp,
    //             utm: {
    //                 utm_source,
    //                 utm_medium,
    //                 utm_campaign
    //             }
    //         }
    //     };


}

export const checkToken = async (navigate: NavigateFunction) => {
    const userToken = await storage.loadFromStorage("userToken")
    if (!userToken) {
        navigate("/login")
    }
}

export function removeAccessibilityComponent(i18nLanguage: string) {
    if (!i18nLanguage.includes("he")) {
        const accessibilityComponent = document.getElementsByClassName(
            "accessibility_component"
        )[0] as HTMLElement;
        if (accessibilityComponent) {
            accessibilityComponent.style.display = "none";
        }
    } else {
        const accessibilityComponent = document.getElementsByClassName(
            "accessibility_component"
        )[0] as HTMLElement;
        if (accessibilityComponent) {
            accessibilityComponent.style.display = "block";
        }
    }
}

// export const sendEvent = (action: string, itemName: string, currency: string, value: string) => {
//     window.gtag('event', action, {
//         'item_name': itemName,
//         'currency': currency,
//         'value': value
//     });
// }
