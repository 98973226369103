import { atom } from "recoil";

// global state that responsible for the opening and closing of lets chat modal
export const languageModel = atom({
    key: "languageModel",
    default: "en"
})
export const redirectedToThankYouPage = atom<boolean>({
    key: "redirectedToThankYouPage",
    default: false
})
export const isP0FreeRegion = atom<boolean>({
    key: "isP0FreeRegion",
    default: false
})
export const openActivePremium0Modal = atom<boolean>({
    key: "openActivePremium0Modal",
    default: false
})


