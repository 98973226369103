import React, { useEffect, useState } from "react";
import styles from "./OTPModal.module.scss";
import OtpInput from "react-otp-input";
import { storage } from "../../utils/utils.service";
import LoaderSuccErr from "../../utils/UtilCmps/LoaderSuccErr/LoaderSuccErr.cmp";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { userVerification } from "../../utils/service";
import { useTranslation } from "react-i18next";

const OTPModal = (props: any) => {
  const { closeModal, setStep, setRegistered, isNewUser, userToken } = props;
  const [otp, setOtp] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [t, i18n] = useTranslation();
  const [errorMessage, setErrorMessage] = useState<any>({
    visibility: "hidden",
    message: t("OTP.ErrorMessage"),
  });
  const [respStatus, setRespStatus] = useState<boolean>();
  const user_id = storage.loadFromStorage("userId");
  const user_email = storage.loadFromStorage("userEmail");
  const user_phone = storage.loadFromStorage("userPhone");
  const navigate = useNavigate();

  const sendOtpToServer = async () => {
    setIsLoading(true);
    try {
      // const userToken = userEmailAndToken.token
      const response = await userVerification.signInWithOtp(otp, userToken);
      // console.log("🚀 ~ file: OTPModal.cmp.tsx:21 ~ sendOtpToServer ~ response:", response)
      setRespStatus(true);
      if (isNewUser) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer
          .push({
            event: "sign_up",
            user_id,
            user_email,
            user_phone,
          })(window as any)
          .fbq("track", "CompleteRegistration");
      }
      storage.saveToStorage("userToken", response.token);
      setTimeout(() => {
        setStep(1);
        setRegistered(true);
      }, 1500);
    } catch (err) {
      setIsLoading(false);
      setErrorMessage({ ...errorMessage, visibility: "visible" });
      console.error(err);
    }
  };
  useEffect(() => {
    const showModalTimeOut = setTimeout(() => {
      setShowModal(true);
    }, 500);

    return () => clearTimeout(showModalTimeOut);
  }, []);
  useEffect(() => {
    if (otp.length === 6) {
      sendOtpToServer();
    }
    if (otp.length < 6 && errorMessage.visibility === "visible") {
      setErrorMessage({ ...errorMessage, visibility: "hidden" });
    }
  }, [otp]);
  return (
    <div
      className={styles.OTPModalBackDrop}
      onClick={() => {
        closeModal(false);
      }}
    >
      <div
        className={`${styles.OTPModalContainer} ${
          showModal ? styles.ShowModalContainer : ""
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseRoundedIcon
          onClick={() => closeModal(false)}
          style={{ position: "absolute", top: "3%", right: "2%" }}
        />
        <p>{t("OTP.Header")}</p>
        <OtpInput
          value={otp}
          onChange={(e) => setOtp(e)}
          numInputs={6}
          renderSeparator={<span>&nbsp;</span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus
          inputType="number"
        />
        {isLoading ? (
          <LoaderSuccErr
            isLoading={isLoading}
            afterBackgroundColor={"white"}
            responseStatus={respStatus}
          />
        ) : (
          <p style={{ visibility: errorMessage.visibility }}>
            {errorMessage.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default OTPModal;
