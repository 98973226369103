import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import AboutMyself from "../../PageSections/lp/AboutMySelf/AboutMySelf";
import styles from "./Lp.module.scss";
import Shekel from "../../PageSections/lp/Shekel/Shekel";
import Unichat from "../../PageSections/lp/Unichat/Unichat";
import Suitable from "../../PageSections/lp/Suitable/Suitable";
import UsersConnected from "../../PageSections/lp/UsersConnected/UsersConnected";
import ForStarters from "../../PageSections/lp/ForStarters/ForStarters";
import FAQ from "../../PageSections/lp/FAQ/FAQ";
import { useSetRecoilState } from "recoil";
import {
  languageModel,
  redirectedToThankYouPage,
} from "../../store/atoms/generalAtoms";
import BillindModal from "../../cmps/BillingModal/BillindModal.cmp";
import {
  removeAccessibilityComponent,
  storage,
} from "../../utils/utils.service";
import { useTranslation } from "react-i18next";
import LangSelector from "../../utils/UtilCmps/LangSelector/LangSelector.cmp";
import UniFooter from "../../cmps/UniFooter/UniFooter.cmp";

const BillingPlans = {
  free: "",
  basic: "BSIC799",
  premium: "PRIM1499",
  pro: "PRO1999",
};

const ILPricing = {
  free: 0,
  basic: 29.6,
  premium: 49.9,
  pro: 74.99,
};

const NewSale = () => {
  const setThankYouPage = useSetRecoilState<boolean>(redirectedToThankYouPage);
  const [openBillingModal, setOpenBillingModal] = useState<boolean>(false);
  const user_id = storage.loadFromStorage("userId");
  const { i18n } = useTranslation();
  const setRecoilLangModel = useSetRecoilState(languageModel);

  const setPageLanguage = async () => {
    await i18n.changeLanguage("he-IL");
    setRecoilLangModel("he-IL");
  };

  useLayoutEffect(() => {
    // setPageLanguage();
  }, []);

  useEffect(() => {
    // setThankYouPage(true);
    return () => setThankYouPage(false);
  }, []);

  useEffect(() => {
    console.log(
      "🚀 ~ file: Lp.tsx:54 ~ useEffect ~ i18n.language:",
      i18n.language
    );
    removeAccessibilityComponent(i18n.language);
  }, [i18n.language]);

  const chooseBillingPlan = (annualBilling: boolean) => {
    // console.log("🚀 ~ file: PricingPlanCards.cmp.tsx:60 ~ chooseBillingPlan ~ annualBilling:", annualBilling, togglePlanState)
    // setChosenBillingPlan(BillingPlans[`${planType}`])

    storage.saveToSessionStorage("billingPlanParams", {
      planId: BillingPlans.pro,
      yearly: "false",
    });
  };

  const onclickOpenModal = useCallback((annualBilling: boolean = false) => {
    // const currency = !recoilLangModel.toLocaleLowerCase().includes('he') ? "USD" : "ILS";
    const currency = "ILS";
    const price = ILPricing["pro"];

    const dataLayerPlanData = {
      event: "add_to_cart",
      user_id: user_id,
      // user_id: userPhoneParam.replace(/^\+/, ""),
      currency: currency,
      value: price,
      item_name: "PRO",
      items: [
        {
          item_id: BillingPlans["pro"],
          quantity: 1,
          price: price,
          currency: currency,
        },
      ],
    };

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(dataLayerPlanData);
    (window as any).fbq("track", "AddToCart");
    // sendEvent('add_to_cart', planType, currency, price);
    // console.log("🚀 ~ file: PricingPlanCards.cmp.tsx:112 ~ onclickOpenModal ~ (window as any).dataLayer:", (window as any).dataLayer)
    storage.saveToSessionStorage("dataLayerCheckOutData", dataLayerPlanData);

    chooseBillingPlan(annualBilling);
    setOpenBillingModal(true);
  }, []);

  return (
    <div className={styles.newSaleMainFlexContainer}>
      <div className={styles.newSaleMainContainer}>
        {/* <div className={styles.langMenuContainer}>
          <LangSelector />
        </div> */}
        <Shekel openModalFunction={onclickOpenModal} />
        <AboutMyself openModalFunction={onclickOpenModal} />
        <Unichat openModalFunction={onclickOpenModal} />
        <UsersConnected openModalFunction={onclickOpenModal} />
        <Suitable openModalFunction={onclickOpenModal} />
        <ForStarters openModalFunction={onclickOpenModal} />
        <FAQ openModalFunction={onclickOpenModal} />
        {openBillingModal && (
          <BillindModal setOpenBillingModal={setOpenBillingModal} isWhatsApp />
        )}
        <UniFooter />
      </div>
    </div>
  );
};

export default NewSale;
