import React, { useState } from 'react';
import styles from './BillingIframe.module.scss'
import { useRecoilState, useRecoilValue } from 'recoil'
import { billingIframeUrl } from '../../store/atoms/pricingPlanAtom'
import LoaderSuccErr from '../../utils/UtilCmps/LoaderSuccErr/LoaderSuccErr.cmp';
import { useTranslation } from 'react-i18next';


export default function BillingIframe(props: any) {
    const { iFrameUrl, showIframeErrorMessage } = props
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [t, i18n] = useTranslation();
    // const [iFrameUrl, setIframeUrl] = useRecoilState(billingIframeUrl)
    return (
        <>
            {showIframeErrorMessage ? <div className={styles.somethingWentWrongMsgContainer}><span>😢<br />{t("BillingModal.iframe.error400")}</span></div>
                : <>
                    <span className={styles.iFrameLoader}><LoaderSuccErr isLoading={isLoading} afterBackgroundColor="white" /></span>
                    <iframe className={styles.BillingIframe} title="Billing Iframe" src={iFrameUrl} onLoad={() => setIsLoading(false)}></iframe>
                    {/* <iframe className={styles.BillingIframe} title="Billing Iframe" src={'http://localhost:3000/thank-you-page'} onLoad={() => setIsLoading(false)}></iframe> */}
                </>}
        </>
    )
}