import React, { useEffect, useState } from "react";
import Styles from "./StartFreeButton.module.scss";
import whiteWhatsAppIMG from "../../assets/homePageAssets/whiteWhatsappIcon.png";
import whatsAppIMG from "../../assets/homePageAssets/whatsappIcon.png";
import { WA_LINK, chatLink } from "../../utils/constant";
// Recoil imports
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { openLetsChatModal } from "../../store/atoms/contactUsAtom";
import { extractUserData, storage } from "../../utils/utils.service";
import { isP0FreeRegion, openActivePremium0Modal } from "../../store/atoms/generalAtoms";

interface StartFreeButtonProps {
  title: string;
  negative?: boolean;
  customStyle?: any;
  linkToRedirect?: string;
  onClickMethod?: () => void;
  icon?: string;
  access_click?: boolean;
  scrollToTop?: boolean;
  phoneInput?: boolean;
}


const user_id = storage.loadFromStorage("userId")
const user_email = storage.loadFromStorage("userEmail")
const user_phone = storage.loadFromStorage("userPhone")

export default function StartFreeButton({ title, negative, customStyle, linkToRedirect, onClickMethod, icon, access_click, scrollToTop, phoneInput }: StartFreeButtonProps) {
  const [openChatModal, setOpenChatModal] = useRecoilState(openLetsChatModal)
  const setOpenActivePremium0Modal = useSetRecoilState(openActivePremium0Modal)
  const isFreeRegion = useRecoilValue(isP0FreeRegion)
  const chosenImage = negative ? whiteWhatsAppIMG : whatsAppIMG;
  const [utmDataString, setUtmDataString] = useState<string>("")

  useEffect(() => {
    if (access_click) {
      const { utm_campaign, utm_medium, utm_source } = extractUserData.extractUrlParams()
      if (utm_campaign || utm_medium || utm_source) {
        setUtmDataString(`&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`)
      }
    }
  }, [])

  const redirectToChatAndSendAnalytics = () => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: access_click ? "access_click" : "start_free_click",
      user_id,
      user_email: user_email ? user_email.toLocaleLowerCase()! : null,
      user_phone
    })
    if (!isFreeRegion) {
      access_click ? window.open(`${process.env.REACT_APP_CHAT_URL}/login?p0=true${utmDataString}`) : window.open(!linkToRedirect ? `${process.env.REACT_APP_CHAT_URL}/login` : linkToRedirect, "_blank")
    } else {
      setOpenActivePremium0Modal(true);
    }
  }


  // const onclickRedirect = () => {

  //   !linkToRedirect ? redirectToChatAndSendAnalytics() : window.open(linkToRedirect, "_blank")
  // };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (phoneInput && onClickMethod) {
        onClickMethod()
      }
    }
  }
  return (
    <>
      {access_click ? <a style={customStyle} href={`${process.env.REACT_APP_CHAT_URL}/login?p0=true${utmDataString}`} title={title} className={`${Styles.accessLinkATag} ${negative ? Styles.accessLinkATagNegative : ""
        }`}>
        <p className={Styles.StartFreeButtonTitle}>{title}</p>
      </a>
        : <button
          type="button"
          style={customStyle}
          title={title}
          className={`${Styles.StartFreeButton} ${negative ? Styles.StartFreeButtonNegative : ""
            }`}
          // onClick={() => { onClickMethod ? onClickMethod() : setOpenChatModal(!openChatModal) }}
          onKeyDown={handleKeyDown}
          onClick={() => {
            if (scrollToTop) {
              const upperDiv = document.getElementById("midjourney");
              if (upperDiv) {
                upperDiv.scrollIntoView({ behavior: "smooth", block: "start" });

              }
              return;
            }
            if (onClickMethod) {
              (window as any).dataLayer = (window as any).dataLayer || [];
              (window as any).dataLayer.push({
                event: "start_free_whatsapp_click",
                user_id,
                user_email: user_email ? user_email.toLocaleLowerCase()! : null,
                user_phone
              })
              onClickMethod()
            } else {
              redirectToChatAndSendAnalytics()
            }
          }}
        >

          {icon ? <div className={Styles.ButtonIconAndTitle}>
            <img src={icon} alt="whatsappIcon" />
            <p className={Styles.StartFreeButtonTitle}>{title}</p>
          </div>
            :
            <p className={Styles.StartFreeButtonTitle}>{title}</p>
          }
        </button>
      }</>
  );
}
