import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import styles from "./UsersConnected.module.scss";
import { useTranslation } from "react-i18next";

// const NextArrow = ({ onClick }: any) => (
//   <div className={styles.nextArrow} onClick={onClick}>
//     <IoIosArrowForward className={styles.arrowIcon} />
//   </div>
// );

// const PrevArrow = ({ onClick }: any) => (
//   <div className={styles.prevArrow} onClick={onClick}>
//     <IoIosArrowBack className={styles.arrowIcon} />
//   </div>
// );

// const UsersConnected = () => {
//   const settings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1300,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

const NextArrow = ({ onClick }: any) => (
  <div className={styles.nextArrow} onClick={onClick}>
    <IoIosArrowForward className={styles.arrowIcon} />
  </div>
);

const PrevArrow = ({ onClick }: any) => (
  <div className={styles.prevArrow} onClick={onClick}>
    <IoIosArrowBack className={styles.arrowIcon} />
  </div>
);

interface SectionProps {
  openModalFunction: () => void;
}

const UsersConnected = ({ openModalFunction }: SectionProps) => {
  const { t, i18n } = useTranslation();
  const [isLtr, setIsLtr] = useState(!i18n.language.includes("he"));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {/* <div className={styles.blackSection}>
        <div>
          <h2 className={styles.mainTitle}>
            למעלה מ- 150 אלף משתמשים כבר התחברו למערכת.. הנה מה שכתבו לנו
          </h2>
          <div className={styles.sliderContainer}>
            <Slider {...settings}>
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div key={index} className={styles.sliderItem}>
                  <img
                    src="/assets/users.svg"
                    alt={`User review ${index}`}
                    className={styles.reviewImage}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className={styles.blackSection}>
        <div className={styles.carouselContainer}>
          <h2 className={styles.mainTitle}>
            למעלה מ- 150 אלף משתמשים כבר התחברו למערכת.. הנה מה שכתבו לנו
          </h2>
          <div className={styles.sliderContainer}>
            <Slider {...settings}>
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div key={index} className={styles.sliderItem}>
                  <img
                    src="/assets/users.svg"
                    alt={`User review ${index}`}
                    className={styles.reviewImage}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div> */}

      <div className={`${styles.whiteSection} ${isLtr ? styles.ltr : ""}`}>
        <div className={styles.innerContent}>
          <h2 className={styles.subTitle}>
            {t("LpPage.usersConnected.subTitle")}
          </h2>
          <h3 className={styles.redTitle}>
            {t("LpPage.usersConnected.redTitle")}
          </h3>
          <ul className={`${styles.benefitsList} ${isLtr ? styles.ltr : ""}`}>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.benefit1")}
            </li>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.benefit2")}
            </li>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.benefit3")}
            </li>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.benefit4")}
            </li>
          </ul>
          <h2 className={styles.bottomText}>
            {t("LpPage.usersConnected.bottomText")}
          </h2>
        </div>
      </div>

      <div className={`${styles.blackSection} ${isLtr ? styles.ltr : ""}`}>
        <div className={styles.container}>
          <h2 className={styles.sectionTitle}>
            {t("LpPage.usersConnected.sectionTitle")}
          </h2>
          <ul className={`${styles.priceList} ${isLtr ? styles.ltr : ""}`}>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.price1")}
            </li>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.price2")}
            </li>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.price3")}
            </li>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.price4")}
            </li>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.price5")}
            </li>
            <li>
              <GoDotFill className={styles.dotIcon} />
              {t("LpPage.usersConnected.price6")}
            </li>
          </ul>

          <p className={`${styles.boldText} ${isLtr ? styles.ltr : ""}`}>
            {t("LpPage.usersConnected.boldText")}
          </p>
          <p className={`${styles.centerText} ${isLtr ? styles.ltr : ""}`}>
            {t("LpPage.usersConnected.centerText")}
          </p>

          <h2 className={styles.priceTitle}>
            {t("LpPage.usersConnected.priceTitle1")}
          </h2>
          <div className={styles.priceComparison}>
            <h4>{t("LpPage.usersConnected.oneTime")}</h4>
            <span className={styles.currency}>
              ₪<del>730</del>
            </span>

            <h4>+ {t("LpPage.usersConnected.perMonth")}</h4>
            <span className={styles.currency}>
              ₪<del>350</del>
            </span>
          </div>
          <h2 className={styles.priceTitle}>
            {t("LpPage.usersConnected.priceTitle2")}
          </h2>
          <h2 className={styles.finalPrice}>
            <span className={styles.currency}>₪75</span>(
            {t("LpPage.usersConnected.perMonth")})
          </h2>
        </div>

        <div className={styles.ctaContainer}>
          <h2 className={styles.ctaTitle}>
            {t("LpPage.usersConnected.ctaTitle")}
          </h2>
          <p className={styles.ctaText}>{t("LpPage.usersConnected.ctaText")}</p>
          <div className={styles.ctaButtons}>
            <img
              src="/assets/arrow-down.svg"
              alt="Arrow down"
              className={styles.arrowDown}
            />
            <div className={styles.trialButton} onClick={openModalFunction}>
              <button className={styles.trialText}>
                {t("LpPage.FreeTrialButton.button")}
              </button>
              <div className={styles.trialDays}>
                <h2 className={styles.trialNumber}>
                  {t("LpPage.FreeTrialButton.number")}
                </h2>
                <h4 className={styles.trialDaysText}>
                  {t("LpPage.FreeTrialButton.days")}
                </h4>
              </div>
            </div>
            <img
              src="/assets/arrow-down.svg"
              alt="Arrow down"
              className={styles.arrowDown}
            />
          </div>
          <button className={styles.startButton} onClick={openModalFunction}>
            {t("LpPage.usersConnected.startButton")}
          </button>
          <img
            src="/assets/cards.svg"
            alt="Payment cards"
            className={styles.paymentCards}
          />
          <p className={styles.disclaimer}>
            {t("LpPage.usersConnected.disclaimer")}
          </p>
          <p className={styles.finalText}>
            {t("LpPage.usersConnected.finalText")}
          </p>
        </div>
      </div>
    </>
  );
};

export default UsersConnected;
